package core.httpClient

import core.api.v1.dashboard.events.EventsDTOOut
import core.environment.EnvironmentConstants
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.utils.io.core.*
import kotlinx.browser.window
import kotlinx.serialization.json.Json

class HttpClientJS {

    // ----------------------------------------------------------------------------
    // Public properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private properties
    // ----------------------------------------------------------------------------

    private val mApiVersion = 1

    private val mCurrentUrl = window.location

    private val mClient = HttpClient(Js) {
        followRedirects = true
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                ignoreUnknownKeys = true
                isLenient = true
            })
        }
        defaultRequest {
            headers {
                append(EnvironmentConstants.apiHeaderAuthKey, EnvironmentConstants.apiHeaderAuthValue)
            }
            url {
                protocol = if (mCurrentUrl.hostname == "0.0.0.0" || mCurrentUrl.hostname.contains("192.168.178")) URLProtocol.HTTP else URLProtocol.HTTPS
                host = mCurrentUrl.hostname
                port = mCurrentUrl.port.toIntOrNull() ?: if (mCurrentUrl.hostname == "0.0.0.0") 80 else 443
            }
        }
    }

    // ----------------------------------------------------------------------------
    // Internal properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Init
    // ----------------------------------------------------------------------------
    init {

    }

    // ----------------------------------------------------------------------------
    // Constructor
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // System/Overridden methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    fun currentHost(): String {
        return "${mCurrentUrl.protocol}//${mCurrentUrl.hostname}:${mCurrentUrl.port}"
    }

    suspend fun getEvents(): List<EventsDTOOut> {
        mClient.use { client ->
            return kotlin.runCatching {
                client.get("/api/v$mApiVersion/events").body<List<EventsDTOOut>>()
            }.getOrElse { listOf() }
        }
    }

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Interfaces/Listener
    // ----------------------------------------------------------------------------

}