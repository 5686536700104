package core.api.v1.ticket

import breakingbeats.modules.api.v1.ticket.HunterContactDTOOut
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EventHunterDTOOut(

    @SerialName("id")
    val mId: Int,

    @SerialName("supplierId")
    val mSupplierId: Int,

    @SerialName("name")
    val mName: String,

    @SerialName("contact")
    val mContact: List<HunterContactDTOOut>,

    @SerialName("reserved")
    val mReserved: Int,

    @SerialName("sold")
    val mSold: Int,

    @SerialName("total")
    val mTotal: Int
)
