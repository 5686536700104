package breakingbeats.modules.api.v1.ticket

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class HunterContactDTOOut(
    @SerialName("hunterId")
    val mHunterId: Int,

    @SerialName("platform")
    val mPlatform: String,

    @SerialName("name")
    val mName: String,

    @SerialName("url")
    val mUrl: String
)
