package breakingbeats.modules.api.v1.ticket

import core.api.v1.ticket.EventHunterDTOOut
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EventSupplierDTOOut(
    @SerialName("id")
    val mId: Int,

    @SerialName("eventId")
    val mEventId: List<Int>,

    @SerialName("name")
    val mName: String,

    @SerialName("hunter")
    val mHunter: List<EventHunterDTOOut>,

    @SerialName("description")
    val mDescription: String,

    @SerialName("imageUrl")
    val mImageUrl: String?
)
