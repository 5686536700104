package core.extensions

fun Double.toEuroString(): String {

    val stringValue = this.toString()
    val parts = stringValue.split(".")

    return if (parts.size > 1) {
        val decimalPart = parts[1]
        "${parts[0]},${decimalPart.padEnd(2, '0')} €"
    } else {
        "$stringValue,00 €"
    }
}