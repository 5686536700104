package core

enum class EColors(val hex: String) {

    SEED("#0078c6"),

    TIX_FOR_GIGS_ORANGE("#F91"),

    PRIMARY_LIGHT("#0061A2"),

    PRIMARY_DARK("#9DCAFF")

    /*<color name="md_theme_light_onPrimary">#FFFFFF</color>
    <color name="md_theme_light_primaryContainer">#D1E4FF</color>
    <color name="md_theme_light_onPrimaryContainer">#001D35</color>
    <color name="md_theme_light_secondary">#206C2F</color>
    <color name="md_theme_light_onSecondary">#FFFFFF</color>
    <color name="md_theme_light_secondaryContainer">#A6F5A8</color>
    <color name="md_theme_light_onSecondaryContainer">#002106</color>
    <color name="md_theme_light_tertiary">#845400</color>
    <color name="md_theme_light_onTertiary">#FFFFFF</color>
    <color name="md_theme_light_tertiaryContainer">#FFDDB6</color>
    <color name="md_theme_light_onTertiaryContainer">#2A1800</color>
    <color name="md_theme_light_error">#BA1A1A</color>
    <color name="md_theme_light_errorContainer">#FFDAD6</color>
    <color name="md_theme_light_onError">#FFFFFF</color>
    <color name="md_theme_light_onErrorContainer">#410002</color>
    <color name="md_theme_light_background">#F8FDFF</color>
    <color name="md_theme_light_onBackground">#001F25</color>
    <color name="md_theme_light_surface">#F8FDFF</color>
    <color name="md_theme_light_onSurface">#001F25</color>
    <color name="md_theme_light_surfaceVariant">#DFE2EB</color>
    <color name="md_theme_light_onSurfaceVariant">#42474E</color>
    <color name="md_theme_light_outline">#73777F</color>
    <color name="md_theme_light_inverseOnSurface">#D6F6FF</color>
    <color name="md_theme_light_inverseSurface">#00363F</color>
    <color name="md_theme_light_inversePrimary">#9DCAFF</color>
    <color name="md_theme_light_shadow">#000000</color>
    <color name="md_theme_light_surfaceTint">#0061A2</color>
    <color name="md_theme_light_outlineVariant">#C3C7CF</color>
    <color name="md_theme_light_scrim">#000000</color>
    <color name="md_theme_dark_primary">#9DCAFF</color>
    <color name="md_theme_dark_onPrimary">#003257</color>
    <color name="md_theme_dark_primaryContainer">#00497C</color>
    <color name="md_theme_dark_onPrimaryContainer">#D1E4FF</color>
    <color name="md_theme_dark_secondary">#8BD88E</color>
    <color name="md_theme_dark_onSecondary">#003910</color>
    <color name="md_theme_dark_secondaryContainer">#00531B</color>
    <color name="md_theme_dark_onSecondaryContainer">#A6F5A8</color>
    <color name="md_theme_dark_tertiary">#FFB959</color>
    <color name="md_theme_dark_onTertiary">#462B00</color>
    <color name="md_theme_dark_tertiaryContainer">#643F00</color>
    <color name="md_theme_dark_onTertiaryContainer">#FFDDB6</color>
    <color name="md_theme_dark_error">#FFB4AB</color>
    <color name="md_theme_dark_errorContainer">#93000A</color>
    <color name="md_theme_dark_onError">#690005</color>
    <color name="md_theme_dark_onErrorContainer">#FFDAD6</color>
    <color name="md_theme_dark_background">#001F25</color>
    <color name="md_theme_dark_onBackground">#A6EEFF</color>
    <color name="md_theme_dark_surface">#001F25</color>
    <color name="md_theme_dark_onSurface">#A6EEFF</color>
    <color name="md_theme_dark_surfaceVariant">#42474E</color>
    <color name="md_theme_dark_onSurfaceVariant">#C3C7CF</color>
    <color name="md_theme_dark_outline">#8D9199</color>
    <color name="md_theme_dark_inverseOnSurface">#001F25</color>
    <color name="md_theme_dark_inverseSurface">#A6EEFF</color>
    <color name="md_theme_dark_inversePrimary">#0061A2</color>
    <color name="md_theme_dark_shadow">#000000</color>
    <color name="md_theme_dark_surfaceTint">#9DCAFF</color>
    <color name="md_theme_dark_outlineVariant">#42474E</color>
    <color name="md_theme_dark_scrim">#000000</color>*/

    // ----------------------------------------------------------------------------
    // Enumeration List
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------
}