package core.api.v1.dashboard.events

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EventTicketsDTOOut(

    @SerialName("id")
    val mId: Int,

    @SerialName("eventId")
    val mEventId: Int,

    @SerialName("name")
    val mName: String,

    @SerialName("onlineTicketUrl")
    val mOnlineTicketUrl: String?,

    @SerialName("available")
    val mAvailable: Boolean,

    @SerialName("price")
    val mPrice: Double

)
