package core.environment

class EnvironmentConstants {

    companion object {
        const val stripePublishableKey_LIVE = "pk_live_51IehkkAixQ5pD6UzLSh3RohUje5pRCxEpBIqis12CP0ozMRIFBODZb8EvjVST5SDmRb1iaJ0h4DLdHQRruRFDqco00JTGiVJ59"
        const val stripePublishableKey_TEST = "pk_test_51IehkkAixQ5pD6UzE2y1HG5YB53JCtg52KYulTyqn7V90b6pVLHdWpSURcQPN6pfMNhgSncHG1baauZYQtDeGUwD00zNIwUAvY"

        const val stripeSecretKey_TEST = "sk_test_51IehkkAixQ5pD6UzLbxZ0iOYr6SqDmnz2mPtjpl6kpKzaAlyMBQ8hb4LR3x6bsPUHMop3WRGpNtvMjEXughgN06D00S9HOh80Z"
        const val stripeSecretKey_LIVE = "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"

        const val googleMapsEmbedAPIKey = "AIzaSyD8sLeUh4RHOoHRnbZ2f9eI_NoHBpRQsjc"

        const val apiHeaderAuthKey = "apiAuth"
        const val apiHeaderAuthValue = "jDfdLUGzkfSumbtaXVGG"

        const val apiParameterAuthKey = "auth"
        const val apiParameterAuthValue = "UQLcFKHSeqEDAQWTJSQr"
    }

}