package core.api.v1.dashboard.events

import breakingbeats.modules.api.v1.ticket.EventSupplierDTOOut
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EventsDTOOut(
    @SerialName("id")
    val mId: Int,

    @SerialName("title")
    val mTitle: String,

    @SerialName("subtitle")
    val mSubtitle: String,

    @SerialName("text")
    val mText: String,

    @SerialName("startDate")
    val mStartDate: Long,

    @SerialName("endDate")
    val mEndDate: Long,

    @SerialName("location")
    val mLocation: String,

    @SerialName("minAge")
    val mMinAge: Int,

    @SerialName("tickets")
    val mTickets: List<EventTicketsDTOOut>,

    @SerialName("supplier")
    val mSupplier: List<EventSupplierDTOOut>,

    @SerialName("previewImage")
    val mPreviewImage: String?,

    @SerialName("fullImage")
    val mFullImage: String?,

    @SerialName("sortingPosition")
    val mSortingPosition: Int
)
