package de.eyemobile.common.utils

import de.eyemobile.common.extensions.twoDigits
import kotlinx.datetime.*

class TimeUtils(
    private val mTimeZone: TimeZone? = null
) {
    companion object {
        fun LocalDateTime.toMillis(timeZone: TimeZone): Long {
            return this.toInstant(timeZone).toEpochMilliseconds()
        }

        fun LocalDateTime.toSimpleDateString(): String {
            return "${this.dayOfMonth.twoDigits()}.${this.monthNumber.twoDigits()}.${this.year.twoDigits()}"
        }

        fun LocalDateTime.toSimpleTimeString(): String {
            return "${this.hour.twoDigits()}:${this.minute.twoDigits()} Uhr"
        }

        fun LocalDateTime.toLocalDateTimeISOString(): String {
            return "${this.year.twoDigits()}-${this.monthNumber.twoDigits()}-${this.dayOfMonth.twoDigits()}T${this.hour.twoDigits()}:${this.minute.twoDigits()}"
        }

        fun LocalDateTime.toSimpleTimeRangeString(endDate: LocalDateTime): String {
            return "${this.hour.twoDigits()}:${this.minute.twoDigits()} - ${endDate.hour.twoDigits()}:${endDate.minute.twoDigits()} Uhr"
        }
    }

    val currentLocalTime: LocalTime
        get() = localDateTime.time

    val currentLocalDate: LocalDate
        get() = localDateTime.date

    val currentTimeMillis: Long
        get() = currentDateTimeInstant.toEpochMilliseconds()

    fun getLocalDateTimeFromMillis(timeMillis: Long): LocalDateTime {
        return Instant.fromEpochMilliseconds(timeMillis).toLocalDateTime(timeZone)
    }

    private val currentDateTimeInstant: Instant
        get() = Clock.System.now()

    private val localDateTime: LocalDateTime
        get() = currentDateTimeInstant.toLocalDateTime(timeZone)

    private val timeZone: TimeZone
        get() = mTimeZone ?: TimeZone.currentSystemDefault()
}