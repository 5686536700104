package modules

import androidx.compose.runtime.*
import core.api.v1.dashboard.events.EventsDTOOut
import core.environment.EnvironmentConstants
import core.styles.Styles
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun UpcomingEventTicketComponent(
    mNextEvent: EventsDTOOut?
) {

    var mHunterVisibilityForSupplierId by remember { mutableStateOf<Int?>(null) }

    val mIsMobile by produceState(false) {
        value = window.innerWidth < Styles.cssConstants.breakPointMobile
    }

    Div({
        classes(Styles.cssClasses.column)
    }) {
        Div({
            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.margin32)
            style {
                width(100.percent)
            }
        }) {
            Div({
                classes(Styles.cssClasses.rowCentered)
            }) {
                P({
                    classes(Styles.cssClasses.h4, Styles.cssClasses.bold, Styles.cssClasses.ibmFontSemiBold, Styles.cssClasses.margin8)
                }) {
                    Text("Wo möchtest du dein Ticket kaufen?")
                }
            }

            Div({
                classes(Styles.cssClasses.flexboxContainer)
                style {
                    justifyContent(JustifyContent.SpaceAround)
                }
            }) {
                mNextEvent?.let { nextEvent ->
                    nextEvent.mSupplier.forEach { supplier ->
                        Div({
                            classes(
                                if (mHunterVisibilityForSupplierId == supplier.mId) Styles.cssClasses.card else Styles.cssClasses.cardDisabled,
                                Styles.cssClasses.margin16
                            )
                            onClick {
                                mHunterVisibilityForSupplierId =
                                    if (mHunterVisibilityForSupplierId == supplier.mId) null else supplier.mId
                            }
                            style {
                                cursor("pointer")
                            }
                        }) {
                            Div({
                                classes(Styles.cssClasses.rowCentered)
                            }) {
                                Img(supplier.mImageUrl.orEmpty()) {
                                    classes(Styles.cssClasses.cardImage)
                                }
                                Div({
                                    classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.column)
                                }) {
                                    P({
                                        classes(Styles.cssClasses.title, Styles.cssClasses.ibmFontMedium)
                                    }) {
                                        Text(supplier.mName)
                                    }

                                    P({
                                        classes(Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                    }) {
                                        Text(supplier.mDescription)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        mHunterVisibilityForSupplierId?.let {
            Div({
                classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.margin32)
                style {
                    width(100.percent)
                }
            }) {
                Div({
                    classes(Styles.cssClasses.rowCentered)
                }) {
                    P({
                        classes(
                            Styles.cssClasses.h4,
                            Styles.cssClasses.bold,
                            Styles.cssClasses.ibmFontSemiBold,
                            Styles.cssClasses.margin8
                        )
                    }) {
                        mNextEvent?.mSupplier?.firstOrNull { it.mId == mHunterVisibilityForSupplierId }?.let {
                            Text("${it.mName} Verkäufer")
                        }
                    }
                }

                mNextEvent?.let { nextEvent ->
                    nextEvent.mSupplier.firstOrNull { it.mId == mHunterVisibilityForSupplierId }?.let { supplier ->

                        Div({
                            style {
                                display(DisplayStyle.Grid)
                                gridTemplateColumns(if (mIsMobile)"100%" else "50% 50%")
                                gridTemplateRows(
                                    supplier.mHunter
                                        .joinToString(" ") { "1fr" }
                                        .plus(if (mIsMobile) "1fr" else "" )
                                )
                            }
                        }) {
                            supplier.mHunter.forEachIndexed { index, hunter ->
                                Div({
                                    style {
                                        gridColumnStart(1)
                                        gridColumnEnd(2)
                                        gridRowStart(index+1)
                                        gridRowEnd(index+2)
                                    }
                                }) {
                                    Div({
                                        classes(Styles.cssClasses.card, Styles.cssClasses.margin16)
                                    }) {
                                        Div({
                                            classes(Styles.cssClasses.column)
                                        }) {
                                            P({
                                                classes(Styles.cssClasses.title, Styles.cssClasses.ibmFontMedium)
                                            }) {
                                                Text(hunter.mName)
                                            }

                                            P({
                                                classes(Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                            }) {
                                                Text("noch verfügbar: ${hunter.mTotal - hunter.mSold}")
                                            }

                                            P({
                                                classes(Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                            }) {
                                                Text("davon reserviert: ${hunter.mReserved}")
                                            }
                                        }
                                    }
                                }
                            }
                            Div({
                                classes(Styles.cssClasses.margin16)
                                style {
                                    gridColumnStart(if (mIsMobile) 1 else 2)
                                    gridColumnEnd(if (mIsMobile) 2 else 3)
                                    gridRowStart(if (mIsMobile) supplier.mHunter.size+1 else 1)
                                    gridRowEnd(if (mIsMobile) supplier.mHunter.size+1 else supplier.mHunter.size+1)
                                    alignSelf("center")
                                    justifySelf("center")
                                }
                            }) {
                                Iframe({
                                    attr("width", "${window.innerWidth.div(if (mIsMobile) 1 else 2).minus(32)}")
                                    attr("height", "${window.innerHeight.div(3).minus(32)}")
                                    attr("frameborder", "0")
                                    style {
                                        border(0.px)
                                    }
                                    attr("referrerpolicy", "no-referrer-when-downgrade")
                                    attr("src", "https://www.google.com/maps/embed/v1/place?key=${EnvironmentConstants.googleMapsEmbedAPIKey}&q=${supplier.mName.replace(" ", "+")}")
                                    attr("allowfullscreen", "true")
                                }) {  }
                            }
                        }

                        P({
                            classes(Styles.cssClasses.h4, Styles.cssClasses.ibmFontLight)
                            style {
                                cursor("pointer")
                            }
                            onClick {
                                window.location.assign("https://www.google.com/maps/search/${supplier.mName.replace(" ", "+")}")
                            }
                        }) {
                            Text("Möchtest du ${supplier.mName} kontaktieren?")
                        }
                    }
                }
            }
        }
    }
}