import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import core.api.v1.dashboard.events.EventsDTOOut
import core.httpClient.HttpClientJS
import core.styles.Styles
import kotlinx.browser.window
import kotlinx.datetime.Clock
import modules.UpcomingEventComponent
import modules.UpcomingEventTicketComponent
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable

fun main() {
    renderComposable(rootElementId = "content") {
        Style(Styles.cssClasses)

        val mClient = HttpClientJS()

        val mEvents by produceState<List<EventsDTOOut>>(emptyList()) {
            value = mClient.getEvents()
        }
        val mNextEvent = remember(mEvents) {
            mEvents
                .sortedBy { it.mStartDate }
                .firstOrNull { it.mStartDate > Clock.System.now().toEpochMilliseconds() }
        }

        val mQueries by produceState(emptyList()) {
            value = window.location.search
                .replace("?","")
                .split("&")
                .map { query ->
                    Pair(
                        first = query.split("=").getOrElse(0) { query },
                        second = query.split("=").getOrNull(1)
                    )
                }
        }

        if (mQueries.contains(Pair("overview", null))) {
            UpcomingEventComponent(
                mNextEvent
            )
        }

        if (mQueries.contains(Pair("tickets", null))) {
            UpcomingEventTicketComponent(
                mNextEvent
            )
        }
    }
}