package core.styles

import core.EColors
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto

object Styles {

    val cssVariables = CSSVariables()
    val cssClasses = CSSClasses()
    val cssConstants = CSSConstants()

    class CSSConstants {
        val breakPointMobile = 768
    }

    class CSSVariables {
        val bodyBackgroundColor by variable<CSSColorValue>()
        val grayBackgroundColor by variable<CSSColorValue>()
        val defaultTextColor by variable<CSSColorValue>()
        val prominentTextColor by variable<CSSColorValue>()
    }

    class CSSClasses : StyleSheet() {

        private val queriesString = window.location.search
        private val darkMode = queriesString.contains("darkmode=true")

        init {
            "body" style {
                if (darkMode) {
                    cssVariables.bodyBackgroundColor(Color("#121212"))
                    cssVariables.grayBackgroundColor(Color("#272727"))
                    cssVariables.defaultTextColor(Color.white)
                } else {
                    cssVariables.bodyBackgroundColor(Color.white)
                    cssVariables.grayBackgroundColor(Color("#F8F8F8"))
                    cssVariables.defaultTextColor(Color.black)
                }
                cssVariables.prominentTextColor(Color(EColors.PRIMARY_DARK.hex))

                margin(0.px)
                backgroundColor(cssVariables.bodyBackgroundColor.value())
                color(cssVariables.defaultTextColor.value())

                fontSize(1.em)
                height(100.percent)
            }
            "a" style {
                textDecoration("none")
                color(cssVariables.defaultTextColor.value())
            }
        }

        val ibmFontBold by style {
            fontFamily("IBM Plex Sans Bold")
        }

        val ibmFontSemiBold by style {
            fontFamily("IBM Plex Sans Semi Bold")
        }

        val ibmFontMedium by style {
            fontFamily("IBM Plex Sans Medium")
        }

        val ibmFontRegular by style {
            fontFamily("IBM Plex Sans Regular")
        }

        val ibmFontLight by style {
            fontFamily("IBM Plex Sans Light")
        }

        val ibmFontExtraLight by style {
            fontFamily("IBM Plex Sans Extra Light")
        }

        val ibmFontThin by style {
            fontFamily("IBM Plex Sans Thin")
        }

        val title by style {
            fontSize(2.5.em)
            margin(0.px)
            textAlign("center")
        }

        val h2 by style {
            fontSize(2.em)
            margin(0.px)
            textAlign("center")
        }

        val h4 by style {
            fontSize(1.5.em)
            margin(0.px)
            textAlign("center")
        }

        val caption by style {
            fontSize(1.em)
            margin(0.px)
            textAlign("center")
        }

        val bold by style {
            fontWeight("bold")
        }

        val margin8 by style {
            margin(8.px)
        }

        val margin16 by style {
            margin(16.px)
        }

        val margin32 by style {
            margin(32.px)
        }

        val rowCentered by style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Row, FlexWrap.Wrap)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            width(100.percent)
        }

        val column by style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            justifyContent(JustifyContent.Start)
            alignItems(AlignItems.Center)
        }

        val flexboxContainer by style {
            display(DisplayStyle.Flex)
            flexFlow(FlexDirection.Row, FlexWrap.Wrap)
            justifyContent(JustifyContent.SpaceBetween)
            alignItems(AlignItems.Center)
        }

        val flexboxItem by style {
            flex(1,1, 0.px)
            alignSelf(AlignSelf.Center)
        }

        val bbLogoLarge by style {
            maxWidth(25.percent)
            height(auto)
        }

        val cardImage by style {
            maxHeight(100.px)
            width(auto)
            borderRadius(8.px)
            marginRight(8.px)
        }

        val footer by style {
            position(Position.Fixed)
            bottom(0.px)
            left(0.px)
            width(100.percent)
            paddingBottom(16.px)
        }

        val card by style {
            borderRadius(20.px)
            padding(16.px)
            backgroundColor(Color(EColors.TIX_FOR_GIGS_ORANGE.hex))
        }

        val cardDisabled by style {
            borderRadius(20.px)
            padding(16.px)
            backgroundColor(Color.lightgray)
        }
    }
}