package modules

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import core.api.v1.dashboard.events.EventsDTOOut
import core.extensions.toEuroString
import core.styles.Styles
import de.eyemobile.common.utils.TimeUtils
import de.eyemobile.common.utils.TimeUtils.Companion.toSimpleDateString
import de.eyemobile.common.utils.TimeUtils.Companion.toSimpleTimeString
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.datetime.TimeZone
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.DOMRect
import org.w3c.dom.HTMLDivElement

@Composable
fun UpcomingEventComponent(
    mNextEvent: EventsDTOOut?
) {
    val mTimeUtils = TimeUtils()

    val cardSize by produceState(DOMRect()) {
        while (window.document.getElementById("card1") == null) {
            delay(100)
        }

        (window.document.getElementById("card1") as? HTMLDivElement)?.let {
            value = it.getBoundingClientRect()
        }
    }

    val isMobile by produceState(false) {
        value = window.innerWidth < Styles.cssConstants.breakPointMobile
    }

    Div({
        classes(Styles.cssClasses.column)
    }) {
        Div({
            classes(Styles.cssClasses.flexboxItem)
            style {
                height(400.px)
            }
        }) {
            Img(mNextEvent?.mFullImage.orEmpty()) {
                style {
                    width(100.vw)
                    height(400.px)
                    property("object-fit", "cover")
                    property("object-position", "50% 0%")
                }
            }
        }

        Div({
            classes(Styles.cssClasses.flexboxItem)
            style {
                position(Position.Relative)
                minWidth(80.vw)
                backgroundColor(Styles.cssVariables.grayBackgroundColor.value())
                borderRadius(0.px, 0.px, 20.px, 20.px)
                paddingLeft(16.px)
                paddingRight(16.px)
                paddingBottom(cardSize.height.div(2).px)
                marginBottom(cardSize.height.div(2).px)
            }
        }) {
            Div({
                classes(Styles.cssClasses.column)
            }) {
                mNextEvent?.let { nextEvent ->
                    P({
                        classes(Styles.cssClasses.title, Styles.cssClasses.ibmFontSemiBold)
                        style {
                            marginTop(32.px)
                        }
                    }) {
                        Text(nextEvent.mTitle)
                    }

                    P({
                        classes(Styles.cssClasses.h4, Styles.cssClasses.ibmFontLight)
                    }) {
                        Text(nextEvent.mSubtitle)
                    }

                    P({
                        classes(Styles.cssClasses.caption, Styles.cssClasses.ibmFontMedium, Styles.cssClasses.margin16)
                    }) {
                        Text(nextEvent.mText)
                    }

                    /*Div({
                        classes(Styles.cssClasses.flexboxContainer)
                    }) {
                        Div({
                            classes(Styles.cssClasses.card, Styles.cssClasses.margin8)
                            style {
                                position(Position.Absolute)
                                bottom(-(cardSize.height.div(2)).px)
                                left(10.percent)
                                transform {
                                    translate((-10).percent, 0.percent)
                                }
                            }
                        }) {
                            Div({
                                classes(Styles.cssClasses.rowCentered)
                            }) {
                                A(
                                    href = "/muttizettel"
                                ) {
                                    P({
                                        classes(Styles.cssClasses.title, Styles.cssClasses.ibmFontThin)
                                    }) {
                                        Text("U18 Muttizettel")
                                    }
                                }
                            }
                        }
                    }*/

                    Div({
                        id("card1")
                        classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.card)
                        style {
                            /*position(Position.Absolute)
                            bottom(-(cardSize.height.div(2)).px)
                            left(50.percent)
                            transform {
                                translate((-50).percent, 0.percent)
                            }*/
                        }
                    }) {
                        Div({
                            classes(if (isMobile) Styles.cssClasses.column else Styles.cssClasses.rowCentered)
                            style {
                                width(70.vw)
                                justifyContent(JustifyContent.SpaceAround)
                                alignItems(AlignItems.Center)
                                alignContent(AlignContent.SpaceAround)
                            }
                        }) {
                            P({
                                classes(
                                    Styles.cssClasses.flexboxItem,
                                    Styles.cssClasses.h4,
                                    Styles.cssClasses.ibmFontThin
                                )
                                style {
                                    if (isMobile) {
                                        width(100.percent)
                                        padding(8.px)
                                    }
                                }
                            }) {
                                Text(nextEvent.mLocation)
                            }

                            //VerticalDivider()

                            Div({
                                classes(
                                    Styles.cssClasses.flexboxItem,
                                    Styles.cssClasses.rowCentered
                                )
                                style {
                                    flexWrap(FlexWrap.Nowrap)
                                    if (isMobile) {
                                        width(100.percent)
                                        padding(8.px)
                                    }
                                }
                            }) {
                                Div({
                                    classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.column)
                                }) {
                                    mTimeUtils.getLocalDateTimeFromMillis(nextEvent.mStartDate).let {
                                        P({
                                            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                        }) {
                                            Text(it.toSimpleDateString())
                                        }

                                        P({
                                            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                        }) {
                                            Text(it.toSimpleTimeString())
                                        }
                                    }
                                }

                                P({
                                    classes(
                                        Styles.cssClasses.flexboxItem,
                                        Styles.cssClasses.caption,
                                        Styles.cssClasses.ibmFontLight,
                                        Styles.cssClasses.margin8
                                    )
                                }) {
                                    Text("bis")
                                }

                                Div({
                                    classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.column)
                                }) {
                                    mTimeUtils.getLocalDateTimeFromMillis(nextEvent.mEndDate).let {
                                        P({
                                            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                        }) {
                                            Text(it.toSimpleDateString())
                                        }

                                        P({
                                            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.caption, Styles.cssClasses.ibmFontLight)
                                        }) {
                                            Text(it.toSimpleTimeString())
                                        }
                                    }
                                }
                            }

                            //VerticalDivider()

                            P({
                                classes(
                                    Styles.cssClasses.flexboxItem,
                                    Styles.cssClasses.h2,
                                    Styles.cssClasses.ibmFontThin
                                )
                                style {
                                    if (isMobile) {
                                        width(100.percent)
                                        padding(8.px)
                                    }
                                }
                            }) {
                                Text("P${nextEvent.mMinAge}")
                            }
                        }
                    }
                }
            }
        }

        Div({
            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.margin32)
        }) {
            Div({
                classes(Styles.cssClasses.rowCentered)
            }) {
                P({
                    classes(Styles.cssClasses.h2, Styles.cssClasses.bold, Styles.cssClasses.ibmFontSemiBold, Styles.cssClasses.margin16)
                }) {
                    Text("verfügbare Tickets")
                }
            }

            Div({
                classes(Styles.cssClasses.flexboxContainer)
                style {
                    justifyContent(JustifyContent.SpaceAround)
                }
            }) {
                mNextEvent?.let { nextEvent ->
                    nextEvent.mTickets.sortedByDescending { it.mAvailable }.forEach { ticket ->
                        Div({
                            classes(
                                Styles.cssClasses.flexboxItem,
                                if (ticket.mAvailable) Styles.cssClasses.card else Styles.cssClasses.cardDisabled,
                                Styles.cssClasses.margin16,
                                Styles.cssClasses.rowCentered
                            )
                            style {
                                flexWrap(FlexWrap.Nowrap)
                                if (ticket.mAvailable) cursor("pointer")
                            }
                            onClick {
                                if (ticket.mAvailable) {
                                    if (ticket.mOnlineTicketUrl.isNullOrBlank().not()) {
                                        ticket.mOnlineTicketUrl?.let { window.location.assign(it) }
                                    } else {
                                        window.location.assign("/next-event"
                                            .plus("?tickets")
                                            .plus(if (window.location.search.contains("darkmode=true")) "&darkmode=true" else "")
                                        )
                                    }
                                }
                            }
                        }) {
                            Img(nextEvent.mPreviewImage.orEmpty(), String()) {
                                classes(Styles.cssClasses.cardImage)
                            }
                            Div({
                                classes(Styles.cssClasses.column)
                            }) {
                                P({
                                    classes(
                                        Styles.cssClasses.flexboxItem,
                                        Styles.cssClasses.title,
                                        Styles.cssClasses.ibmFontSemiBold
                                    )
                                }) {
                                    Text(ticket.mName)
                                }

                                P({
                                    classes(
                                        Styles.cssClasses.flexboxItem,
                                        Styles.cssClasses.h4,
                                        Styles.cssClasses.ibmFontLight
                                    )
                                }) {
                                    Text(ticket.mPrice.toEuroString())
                                }
                            }
                        }
                    }
                }
            }
        }

        Div({
            classes(Styles.cssClasses.flexboxItem, Styles.cssClasses.margin32)
        }) {
            Div({
                classes(Styles.cssClasses.rowCentered)
            }) {
                P({
                    classes(Styles.cssClasses.h2, Styles.cssClasses.bold, Styles.cssClasses.ibmFontSemiBold, Styles.cssClasses.margin16)
                }) {
                    Text("U18 Gäste")
                }
            }

            Div({
                classes(Styles.cssClasses.flexboxContainer)
                style {
                    justifyContent(JustifyContent.SpaceAround)
                }
            }) {
                Div({
                    classes(
                        Styles.cssClasses.flexboxItem,
                        Styles.cssClasses.card,
                        Styles.cssClasses.margin16,
                        Styles.cssClasses.rowCentered
                    )
                    style {
                        flexWrap(FlexWrap.Nowrap)
                        cursor("pointer")
                    }
                    onClick {
                        window.location.assign("/muttizettel")
                    }
                }) {
                    Div({
                        classes(Styles.cssClasses.column)
                    }) {
                        P({
                            classes(
                                Styles.cssClasses.flexboxItem,
                                Styles.cssClasses.title,
                                Styles.cssClasses.ibmFontSemiBold
                            )
                        }) {
                            Text("U18 Muttizettel")
                        }
                    }
                }
            }
        }
    }

    /*
    A(href = "/next-event".plus("?tickets"), {
                            classes(Styles.cssClasses.flexboxItem)
                        }) {
    * */

    /*Footer({
        classes(Styles.cssClasses.footer)
    }) {
        P({
            classes(Styles.cssClasses.caption)
        }) {
            val year = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
            Text("© $year Breaking Beats GbR")
        }
    }*/
}